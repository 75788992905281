import type { Headers, Params } from './types'

const AuthService = class {
  headers: Headers
  authUrl: Params['authUrl']
  authId: Params['authId']
  apiFetch: Params['apiFetch']
  accessToken: string
  sessionId: string

  constructor (params: Params) {
    this.apiFetch = params.apiFetch
    this.authUrl = params.authUrl
    this.authId = params.authId
    this.accessToken = ''
    this.sessionId = ''
    this.headers = {
      'X-Application-Id': params.xApplicationId,
      'Content-Type': 'application/json'
    }
  }

  async validateToken (token: string) {
    try {
      const endpoint = `${this.authUrl}/token/validate`
      const result = await this.apiFetch(endpoint, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          access_token: token
        }
      })

      return result
    } catch (error) {
      console.log(error)
      throw error.data
    }
  }

  async authorizerToken () {
    try {
      const endpoint = `${this.authUrl}/auth/oauth2/token`
      const result = await this.apiFetch(endpoint, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          grant_type: 'client_credentials',
          client_id: this.authId
        }
      })

      this.accessToken = result.access_token
    } catch (error) {
      this.accessToken = ''
    }
  }

  async authorizer (countryCode: string, phone: string, captchaToken: string) {
    try {
      const endpoint = `${this.authUrl}/sso/auth`
      const result = await this.apiFetch(endpoint, {
        method: 'POST',
        headers: {
          ...this.headers,
          'X-Captcha-Token': captchaToken,
          Authorization: `Bearer ${this.accessToken}`
        },
        body: {
          grant_type: 'otp',
          client_id: this.authId,
          auth_data: {
            contact_media: 'SMS',
            country_code: countryCode,
            phone_number: phone
          }
        }
      })

      this.sessionId = result.session
      return result
    } catch (error) {
      throw error.data
    }
  }

  async challenge (code: string, captchaToken: string) {
    try {
      const endpoint = `${this.authUrl}/sso/challenge`
      const result = await this.apiFetch(endpoint, {
        method: 'POST',
        headers: {
          ...this.headers,
          'X-Captcha-Token': captchaToken,
          Authorization: `Bearer ${this.accessToken}`
        },
        body: {
          session: this.sessionId,
          client_id: this.authId,
          challenge: 'confirm_otp',
          data: {
            code
          }
        }
      })

      return result
    } catch (error) {
      throw error.data
    }
  }

  async resendCode (captchaToken: string) {
    try {
      const endpoint = `${this.authUrl}/sso/challenge`
      const result = await this.apiFetch(endpoint, {
        method: 'POST',
        headers: {
          ...this.headers,
          'X-Captcha-Token': captchaToken,
          Authorization: `Bearer ${this.accessToken}`
        },
        body: {
          session: this.sessionId,
          client_id: this.authId,
          challenge: 'resend_otp',
          data: {}
        }
      })

      this.sessionId = result.session

      return result
    } catch (error) {
      throw error.data
    }
  }

  async refresh (refreshToken: string, captchaToken: string) {
    try {
      const endpoint = `${this.authUrl}/sso/auth`
      const result = await this.apiFetch(endpoint, {
        method: 'POST',
        headers: {
          ...this.headers,
          'X-Captcha-Token': captchaToken,
          Authorization: `Bearer ${this.accessToken}`
        },
        body: {
          grant_type: 'refresh_token',
          client_id: this.authId,
          auth_data: {
            refresh_token: refreshToken
          }
        }
      })

      this.sessionId = result.session
      return result
    } catch (error) {
      throw error.data
    }
  }
}

export default AuthService
