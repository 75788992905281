import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import nuxt_plugin_03YjkxYbK5 from "/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_snowplow_client_DAyOijSJXT from "/app/plugins/01.snowplow.client.ts";
import _02_growthbook_client_zUy8uulWh1 from "/app/plugins/02.growthbook.client.ts";
import _03_sobreplanosUrls_FEXFcn3g2D from "/app/plugins/03.sobreplanosUrls.ts";
import _04_sessionId_wkA00bUc5K from "/app/plugins/04.sessionId.ts";
import _05_recaptcha_client_aZ7fE9oCQ5 from "/app/plugins/05.recaptcha.client.ts";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import cdpData_izWiMdiRks from "/app/plugins/cdpData.ts";
import directives_8CcCirWtnE from "/app/plugins/directives.ts";
import error_handler_kEP5FliEXj from "/app/plugins/error-handler.ts";
import gtm_client_OzmBuHLRIb from "/app/plugins/gtm.client.ts";
import imsData_2enN7zuEPC from "/app/plugins/imsData.ts";
import listingData_K8686M9svK from "/app/plugins/listingData.ts";
import sobreplanosData_RUQG84ROBa from "/app/plugins/sobreplanosData.ts";
import strapi_lgLLCZrkNA from "/app/plugins/strapi.ts";
import whatsappNumbers_ANHpLitaIF from "/app/plugins/whatsappNumbers.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  nuxt_plugin_03YjkxYbK5,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _01_snowplow_client_DAyOijSJXT,
  _02_growthbook_client_zUy8uulWh1,
  _03_sobreplanosUrls_FEXFcn3g2D,
  _04_sessionId_wkA00bUc5K,
  _05_recaptcha_client_aZ7fE9oCQ5,
  api_GFfDXud5Cr,
  cdpData_izWiMdiRks,
  directives_8CcCirWtnE,
  error_handler_kEP5FliEXj,
  gtm_client_OzmBuHLRIb,
  imsData_2enN7zuEPC,
  listingData_K8686M9svK,
  sobreplanosData_RUQG84ROBa,
  strapi_lgLLCZrkNA,
  whatsappNumbers_ANHpLitaIF
]