import type { $Fetch } from 'ofetch'
import { REFRESH_TOKEN } from '@/stores/userStore/types'
import AuthService from '@/services/authService'
import { useUserStore } from '@/stores/userStore'

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return

  const lhAuthTokenStatus = useCookie<string|null>('lh_auth_token_status')
  const lhAuthRefreshToken = useCookie<string|null>(REFRESH_TOKEN)

  if (lhAuthTokenStatus.value === 'expired_token' && !lhAuthRefreshToken.value) {
    const authToken = useCookie<string|null>('lh_auth_token')
    try {
      const { $config } = useNuxtApp()

      const UniversalAuthServiceModule = await import('@/services/universalAuthService')
      const UniversalAuthService = UniversalAuthServiceModule.default
      const auth = new UniversalAuthService(
        $config.public.laHausAuthId,
        $config.public.environment
      )

      const token = await auth.getSessionToken()
      authToken.value = token

      lhAuthTokenStatus.value = null

      return await navigateTo(to, {
        external: true
      })
    } catch (error: any) {
      lhAuthTokenStatus.value = null
      authToken.value = null

      const logger = useLogger()
      logger.error({
        message: error.message,
        stack: error
      })
    }
  } else if (lhAuthTokenStatus.value === 'expired_token' && lhAuthRefreshToken.value) {
    try {
      const { $config, $getCaptchaToken } = useNuxtApp()

      lhAuthTokenStatus.value = null

      const authService = new AuthService({
        apiFetch: globalThis.$fetch as $Fetch,
        authUrl: $config.public.authorizationApiUrl as string,
        authId: $config.public.laHausAuthId as string,
        xApplicationId: $config.public.environment
      })

      await authService.authorizerToken()

      const captchaToken = await $getCaptchaToken()

      const result = await authService.refresh(lhAuthRefreshToken.value, captchaToken)

      const userStore = useUserStore()
      if (result.auth_result) {
        userStore.setSession(result)
      }

      return await navigateTo(to, {
        external: true
      })
    } catch (error: any) {
      lhAuthTokenStatus.value = null
      lhAuthRefreshToken.value = null

      const logger = useLogger()
      logger.error({
        message: error.message,
        stack: error
      })
    }
  }
})
