export default defineNuxtPlugin(({ $config }) => {
  const siteKey = $config.public.googleRecaptchaSiteKey as string
  const script = document.createElement('script')

  script.src = 'https://www.google.com/recaptcha/api.js?render=' + siteKey
  script.async = true
  script.defer = true
  document.head.appendChild(script)

  const getCaptchaToken = () => {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      window.grecaptcha.ready(() => {
        // @ts-ignore
        window.grecaptcha.execute(siteKey, { action: 'submit' }).then(resolve).catch(reject)
      })
    })
  }

  return {
    provide: {
      getCaptchaToken
    }
  }
})
